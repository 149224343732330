import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styles from './Header.module.scss';
import logo from '../../../assets/images/logo.svg';
import logoMobile from '../../../assets/images/logo-mobile.svg';
import burguerMenu from '../../../assets/icons/burguer-menu.svg';
import MenuMobile from './menuMobile/MenuMobile';
import { navLinks } from '../../../utils/links';
import routes from '../../../utils/routes';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const mql = window.matchMedia('(min-width: 1000px)');
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname, hash } = location;
    const currentPathname = `${pathname}${hash}`;

    const onLogoClick = () => {
        navigate(routes.home);
    };

    const openMenu = () => {
        setShowMenu(true);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    const onMatchMediaChange = () => {
        if (showMenu) closeMenu();
    };

    useEffect(() => {
        mql.addEventListener('change', onMatchMediaChange);

        return () => window.removeEventListener('change', onMatchMediaChange);
    }, [mql]);

    return (
        <>
            <header className={styles.headerContainer}>
                <div className={styles.headerWrapper}>
                    <button className={styles.logoContainer} onClick={onLogoClick}>
                        <img src={logo} className={styles.logoDesktop} alt="" />
                        <img src={logoMobile} className={styles.logoMobile} alt="" />
                    </button>

                    <div className={styles.navContainer}>
                        <nav className={styles.nav}>
                            <ul className={styles.linksContainer}>
                                {navLinks.map((navLink) => {
                                    const isActive =
                                        `/${navLink.path}` === currentPathname ||
                                        (navLink.name === 'HOME' && currentPathname === '/');
                                    const linkClassName = isActive
                                        ? `${styles.link} ${styles.linkActive}`
                                        : styles.link;

                                    return (
                                        <li key={navLink.name} className={linkClassName}>
                                            <HashLink to={navLink.path} onClick={closeMenu} smooth>
                                                {navLink.name}
                                            </HashLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>
                        {/* <button className={styles.ctaButton} onClick={closeMenu}>
                            BUTTON CTA 1
                        </button> */}
                    </div>

                    <button className={styles.burguerButtonContainer} onClick={openMenu}>
                        <img src={burguerMenu} alt="" />
                    </button>
                </div>
            </header>
            <span className={styles.point}>.</span>
            {showMenu && <MenuMobile closeMenu={closeMenu} />}
        </>
    );
};

export default Header;
