import { HashLink } from 'react-router-hash-link';
import { navLinks, socialLinks } from '../../../../utils/links';
import logoMobile from '../../../../assets/images/logo-mobile.svg';
import styles from './Footer.module.scss';

const Footer = () => (
    <div className={styles.sectionContainer}>
        <div className={styles.sectionWrapper}>
            <div className={styles.socialContainer}>
                <ul>
                    {socialLinks.map((link) => (
                        <li key={link.text}>
                            <a href={link.href} target="_blank" rel="noreferrer">
                                <img src={link.src} className={styles.socialIcon} alt={`${link.text} icon`} />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.logoAndLinks}>
                <img src={logoMobile} className={styles.logo} alt="" />
                <nav className={styles.nav}>
                    <ul className={styles.linksContainer}>
                        {navLinks.map((navLink) => (
                            <li key={navLink.name} className={styles.link}>
                                <HashLink to={navLink.path} smooth>
                                    {navLink.name}
                                </HashLink>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div className={styles.copyright}>
                <p>
                    2022 KUBE ECOSYSTEM SL &copy; <span>All rights reserved</span>
                </p>
                <p>Gran Via 415, 08015, Barcelona, Spain</p>
            </div>
        </div>
    </div>
);

export default Footer;
