import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import arrowLeft from '../../../../../assets/icons/arrow-left.png';
import arrowRight from '../../../../../assets/icons/arrow-right.png';
import hawkDiver from '../../../../../assets/images/hawk-diver.jpg';
import hawkGamer from '../../../../../assets/images/hawk-gamer.jpg';
import hawkHunter from '../../../../../assets/images/hawk-hunter.jpg';
import hawnJoker from '../../../../../assets/images/hawk-joker.jpg';
import hawkPirate from '../../../../../assets/images/hawk-pirate.jpg';
import hawkPolice from '../../../../../assets/images/hawk-police.jpg';
import styles from './Slider.module.scss';

const sliderInitialCards = [
    { id: uuidv4(), img: hawkPirate },
    { id: uuidv4(), img: hawnJoker },
    { id: uuidv4(), img: hawkHunter },
    { id: uuidv4(), img: hawkPolice },
    { id: uuidv4(), img: hawkGamer },
    { id: uuidv4(), img: hawkDiver },
    { id: uuidv4(), img: hawkPirate },
];

interface ICard {
    id: string;
    img: string;
}

type IDirection = 'left' | 'right';

const createNewArray = (array: ICard[], direction: IDirection) => {
    const firstItem = array[0];
    const lastItem = array[array.length - 1];
    let newArray;

    if (direction === 'left') {
        newArray = [...array, { ...firstItem, id: uuidv4() }];
    } else {
        newArray = [{ ...lastItem, id: uuidv4() }, ...array];
    }

    return newArray;
};

const resetStyles = (cards: Element[]) => {
    cards.forEach((item) => {
        const card = item as HTMLImageElement;

        card.setAttribute('style', '');
    });
};

const moveImages = (cards: Element[], direction: IDirection) => {
    const isMovingLeft = direction === 'left';
    const topMovement = isMovingLeft ? 20 : -20;
    const leftMovement = isMovingLeft ? -20 : 20;

    cards.forEach((item, index) => {
        const card = item as HTMLElement;
        const cardImage = card.firstChild as HTMLImageElement;
        const { offsetTop, offsetLeft } = card;
        const isFirstSliderCard = index === 0;
        const isFirstVisibleCard = index === 1;
        const isSecondVisibleCard = index === 2;
        const isLastVisibleCard = index === cards.length - 1;

        const newOffsetTop = offsetTop + topMovement;
        const newOffsetLeft = offsetLeft + leftMovement;

        card.style.top = `${newOffsetTop}px`;
        card.style.left = `${newOffsetLeft}px`;

        if (isMovingLeft) {
            if (isFirstVisibleCard) card.style.opacity = '0';

            if (isSecondVisibleCard) {
                cardImage.style.filter = 'grayscale(0)';
                card.style.transform = 'scale(1)';
                card.style.top = `${newOffsetTop + 20}px`;
                card.style.left = `${newOffsetLeft - 20}px`;
                card.classList.add(styles.activeCard);
            }
            if (isLastVisibleCard) card.style.opacity = '1';
        } else {
            if (isFirstSliderCard) {
                cardImage.style.filter = 'grayscale(0)';
                card.style.opacity = '1';
                card.classList.add(styles.activeCard);
            }
            if (isFirstVisibleCard) {
                cardImage.style.filter = 'grayscale(1)';
                card.style.top = `${newOffsetTop - 20}px`;
                card.style.left = `${newOffsetLeft + 20}px`;
                card.style.transform = 'scale(0.9)';
                card.classList.remove(styles.activeCard);
            }
            if (isLastVisibleCard) card.style.opacity = '0.1';
        }
    });
};

const Slider = () => {
    const [isClickable, setIsClickable] = useState(true);
    const [sliderCards, setSliderCards] = useState(sliderInitialCards);
    const cardsContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const cardsContainer = cardsContainerRef.current!;
        const cards = Array.from(cardsContainer.children);
        const firstVisibleCard = cards[1];
        firstVisibleCard.classList.add(styles.activeCard);
    }, []);

    const onArrowClick = (direction: IDirection) => {
        const isMovingLeft = direction === 'left';
        if (!isClickable) return;
        setIsClickable(false);

        const cardsContainer = cardsContainerRef.current!;
        const cards = Array.from(cardsContainer.children);

        moveImages(cards, direction);

        setTimeout(() => {
            resetStyles(cards);

            const cutSliderCards = isMovingLeft ? sliderCards.slice(1) : sliderCards.slice(0, -1);
            const newSliderCards = createNewArray(cutSliderCards, direction);
            setSliderCards(newSliderCards);
            setIsClickable(true);
        }, 500);
    };

    return (
        <div className={styles.sliderContainer}>
            <button onClick={() => onArrowClick('left')}>
                <img className={styles.arrowLeft} src={arrowLeft} alt="" />
            </button>
            <div className={styles.cardsContainer} ref={cardsContainerRef}>
                {sliderCards.slice(2).map((card) => (
                    <div className={styles.card} key={card.id}>
                        <img src={card.img} alt="" />
                    </div>
                ))}
            </div>
            <button onClick={() => onArrowClick('right')}>
                <img className={styles.arrowRight} src={arrowRight} alt="" />
            </button>
        </div>
    );
};

export default Slider;
