import hawkImg from '../../../../assets/images/cover-hawk.png';
import { socialLinks } from '../../../../utils/links';
import styles from './Cover.module.scss';

const Cover = () => (
    <div id="home" className={styles.sectionContainer}>
        <div className={styles.imageWrapper}>
            <img src={hawkImg} className={styles.hawkImg} alt="" />
        </div>
        <div className={styles.sectionWrapper}>
            <div className={styles.textContainer}>
                <h1 className={styles.title}>ROYAL HAWK CLUB</h1>
                <p className={styles.subtitle}>7777 Unique NFTs, next-generation’s travel leaders</p>
            </div>
        </div>
        <div className={styles.leftVerticalLine}>
            <span>+</span>
            <span>&#10094;&#10094;</span>
            <span>RHC - ROYAL HAWK CLUB</span>
            <span className={styles.verticalLine} />
            <span>KUBE ECOSYSTEM</span>
            <span>DEVICE UI/UX</span>
            <span>&#10095;&#10095;</span>
            <span>+</span>
        </div>
        <div className={styles.rightVerticalLine}>
            <span>+</span>
            <span>&#10094;&#10094;</span>
            <span>HASA SPACESHIP</span>
            <span>YY-XX-2K22</span>
            <span className={styles.verticalLine} />
            <span>3D ARTISTS</span>
            <span>777 HAWK NFT</span>
            <span>&#10095;&#10095;</span>
            <span>+</span>
        </div>
    </div>
);

export default Cover;
