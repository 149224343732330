import instagramIcon from '../assets/icons/instagram-icon.svg';
import twitterIcon from '../assets/icons/twitter-icon.svg';
import telegramIcon from '../assets/icons/telegram-icon.svg';
import discordIcon from '../assets/icons/discord-icon.svg';
import youtubeIcon from '../assets/icons/youtube-icon.svg';

export const navLinks = [
    { name: 'HOME', path: '#home' },
    { name: 'ABOUT US', path: '#aboutUs' },
    { name: 'ROAD MAP', path: '#roadMap' },
    { name: 'OUR TEAM', path: '#ourTeam' },
    { name: 'FAQs', path: '#faqs' },
];

export const socialLinks = [
    { text: 'discord', href: 'https://discord.gg/eKwbHv5w4Y', src: discordIcon },
    { text: 'twitter', href: 'https://twitter.com/royalhawkclub', src: twitterIcon },
    { text: 'telegram', href: 'https://t.me/royalhawkclub', src: telegramIcon },
    { text: 'youtube', href: 'https://www.youtube.com/channel/UC1yT_H_D4yOZwxDtz0M01xQ', src: youtubeIcon },
    { text: 'instagram', href: 'https://www.instagram.com/royalhawkclub', src: instagramIcon },
];
