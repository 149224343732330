import { socialLinks } from '../../../utils/links';
import styles from './SocialNetworks.module.scss';

const SocialNetworks = () => (
    <div className={styles.socialContainer}>
        <div className={styles.socialWrapper}>
            <ul>
                {socialLinks.map((link) => (
                    <li key={link.text}>
                        <a href={link.href} target="_blank" rel="noreferrer">
                            <img src={link.src} className={styles.socialIcon} alt={`${link.text} icon`} />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

export default SocialNetworks;
