import hawkGuitar from '../../../../assets/images/hawk-guitar.png';
import hawkGuitarMobile from '../../../../assets/images/hawk-guitar-mobile.png';
import hawkBeast from '../../../../assets/images/hawk-beast.png';
import hawkBeastMobile from '../../../../assets/images/hawk-beast-mobile.png';
import hawkDisco from '../../../../assets/images/hawk-disco.png';
import hawkDiscoMobile from '../../../../assets/images/hawk-disco-mobile.png';
import hawkArmor from '../../../../assets/images/hawk-armor.png';
import hawkArmorMobile from '../../../../assets/images/hawk-armor-mobile.png';
import hawkMagician from '../../../../assets/images/hawk-magician.png';
import hawkMagicianMobile from '../../../../assets/images/hawk-magician-mobile.png';
import hawkMulticolor from '../../../../assets/images/hawk-multicolor.png';
import hawkMulticolorMobile from '../../../../assets/images/hawk-multicolor-mobile.png';
import styles from './Collection.module.scss';
import Picture from '../../../global/picture/Picture';

const cards = [
    {
        number: 1,
        title: 'Tourist',
        img: hawkGuitar,
        imgMobile: hawkGuitarMobile,
        description: 'Your one-way ticket into the RHC',
    },
    {
        number: 2,
        title: 'Tourist+',
        img: hawkBeast,
        imgMobile: hawkBeastMobile,
        description: 'Added benefits for your jet-setter lifestyle',
    },
    {
        number: 3,
        title: 'Premium',
        img: hawkDisco,
        imgMobile: hawkDiscoMobile,
        description: 'Superior quality comes at a premium price',
    },
    {
        number: 4,
        title: 'Business',
        img: hawkArmor,
        imgMobile: hawkArmorMobile,
        description: 'Take the leap and upgrade for elite rewards',
    },
    {
        number: 5,
        title: 'Vip',
        img: hawkMagician,
        imgMobile: hawkMagicianMobile,
        description: 'Highly-valued members deserve full VIP treatment',
    },
    {
        number: 6,
        title: 'Pilot',
        img: hawkMulticolor,
        imgMobile: hawkMulticolorMobile,
        description: 'Fly high and go far with out-of-this-world benefits',
    },
];

const Collection = () => (
    <div className={styles.sectionContainer}>
        <div className={styles.sectionWrapper}>
            <div className={styles.textContainer}>
                <h2 className={styles.title}>COLLECTION</h2>
                <p className={styles.description}>
                    Our premium NFTs range in exclusivity. The more exclusive the NFT, the more unique benefits you’ll
                    enjoy.
                </p>
            </div>
            <div className={styles.cardsContainer}>
                {cards.map((card) => (
                    <div className={styles.card} key={card.number}>
                        <div className={styles.cardImageContainer}>
                            <Picture
                                className={styles.cardImage}
                                desktopImg={card.img}
                                mobileImg={card.imgMobile}
                                mediaWidth={1000}
                                alt={card.title}
                            />
                            <p className={styles.cardNumber}>{card.number}</p>
                        </div>
                        <div className={styles.cardTextContainer}>
                            <p className={styles.cardName}>{card.title}</p>
                            <p className={styles.cardDescription}>{card.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default Collection;
