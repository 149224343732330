import styles from './TravelClub.module.scss';
import airplaneIcon from '../../../../assets/icons/airplane.svg';
import ticketIcon from '../../../../assets/icons/ticket.svg';
import hotelIcon from '../../../../assets/icons/hotel.svg';
import partyIcon from '../../../../assets/icons/party.svg';
import globeIcon from '../../../../assets/icons/globe.svg';
import decisionsIcon from '../../../../assets/icons/decisions.svg';
import plusIcon from '../../../../assets/icons/plus.svg';

const benefits = [
    { icon: airplaneIcon, text: 'Travel experiences' },
    { icon: ticketIcon, text: 'Tickets to concerts and festivals' },
    { icon: hotelIcon, text: 'Hotels' },
    { icon: partyIcon, text: 'Exclusive parties and events' },
    { icon: globeIcon, text: 'Airdrops' },
    { icon: decisionsIcon, text: 'Decision making (DAO)' },
    { icon: plusIcon, text: 'and much more...' },
];

const TravelClub = () => (
    <div className={styles.sectionContainer}>
        <div className={styles.sectionWrapper}>
            <div className={styles.titleContainer}>
                <p className={styles.surTitle}>USE CASE</p>
                <h2 className={styles.title}>TRAVEL CLUB</h2>
                <p className={styles.description}>
                    Become a part of the Royal Hawk Club and enjoy the perks of our exclusive community:
                </p>
            </div>
            <div className={styles.benefitsContainer}>
                {benefits.map((benefit) => (
                    <div className={styles.benefit} key={benefit.text}>
                        <div className={styles.benefitIcon}>
                            <img src={benefit.icon} alt="" />
                        </div>
                        <p className={styles.benefitText}>{benefit.text}</p>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default TravelClub;
