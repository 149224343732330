import styles from './Roadmap.module.scss';

const Roadmap = () => (
    <div id="roadMap" className={styles.sectionContainer}>
        <h2 className={styles.title}>ROADMAP</h2>
        <div className={styles.phasesContainer}>
            <div className={styles.phase}>
                <p className={styles.phaseNumber}>1</p>
                <div className={styles.textContainer}>
                    <h3 className={styles.phaseTitle}>First Drop</h3>
                    <div>
                        <p className={styles.phaseDesc}>The next generation begins: Public Launch</p>
                    </div>
                </div>
                <div className={styles.lightDesktop1} />
            </div>

            <div className={`${styles.phase} ${styles.phaseMiddle}`}>
                <p className={styles.phaseNumber}>2</p>
                <div className={styles.textContainer}>
                    <h3 className={styles.phaseTitle}>SECOND DROP</h3>
                    <div>
                        <p className={styles.phaseDesc}>Welcome to the Club</p>
                        <p className={styles.phaseDesc}>Start your journey</p>
                    </div>
                </div>
                <div className={styles.lightDesktop2} />
            </div>

            <div className={styles.phase}>
                <p className={styles.phaseNumber}>3</p>
                <div className={styles.textContainer}>
                    <h3 className={styles.phaseTitle}>LAST PHASE</h3>
                    <div>
                        <p className={styles.phaseDesc}>Highway to success</p>
                        <p className={styles.phaseDesc}>Exclusive events</p>
                        <p className={styles.phaseDesc}>Special Collabs</p>
                        <p className={styles.phaseDesc}>Unique benefits</p>
                    </div>
                </div>
                <div className={styles.lightDesktop3} />
            </div>
        </div>

        <div className={styles.lightMobile} />
    </div>
);

export default Roadmap;
