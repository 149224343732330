import Faq from 'react-faq-component';
import styles from './Faqs.module.scss';

const config = {
    openOnload: 0,
};

const faqComponentStyles = {
    arrowColor: '#5bc25b',
};

const faqQuestions = {
    rows: [
        {
            title: 'What is RHC?',
            content:
                'Royal Hawk Club is an NFT project focusing on technology and implementation in the real world, where users will earn benefits and unique perks for being owners, from discounts to free trips. No more being a simple image, let’s use them.',
        },
        {
            title: 'How do I sign up for the Whitelist?',
            content:
                'Simply join our <a href="https://discord.com/invite/y6tS98NtrW" target="_blank" rel="noreferrer">Discord</a> and go to the Whitelist section – there you will find all the information on how to register.',
        },
        {
            title: 'What is the price of each NFT?',
            content: 'Price and maximum purchase quantity will be announced soon. ',
        },
        {
            title: 'Which countries can buy our NFTs?',
            content: 'Anyone from any country can buy our NFTs. ',
        },
        {
            title: 'What tokens will be accepted?',
            content: 'You will be able to buy using KUBE and ADA. ',
        },
    ],
};

const Faqs = () => (
    <div id="faqs" className={styles.sectionContainer}>
        <div className={styles.titleContainer}>
            <h2 className={styles.title}>FAQS</h2>
        </div>
        <div className={styles.faqsContainer}>
            <Faq data={faqQuestions} styles={faqComponentStyles} config={config} />
        </div>
    </div>
);

export default Faqs;
