import AboutUs from './aboutUs/AboutUs';
import Collection from './collection/Collection';
import Cover from './cover/Cover';
import Header from '../../global/header/Header';
import Roadmap from './roadmap/Roadmap';
import styles from './Home.module.scss';
import UseCase from './useCase/UseCase';
import TravelClub from './travelClub/TravelClub';
import OurTeam from './ourTeam/OurTeam';
import Faqs from './faqs/Faqs';
import Footer from './footer/Footer';
import SocialNetworks from '../../global/socialNetworks/SocialNetworks';

const Home = () => (
    <div className={styles.pageContainer}>
        <SocialNetworks />
        <Header />
        <Cover />
        <div className={styles.sectionsContainer}>
            <AboutUs />
            <Collection />
            {/* <UseCase /> */}
            <Roadmap />
            <TravelClub />
            <OurTeam />
            <div className={styles.glitchSections}>
                <Faqs />
                <Footer />
            </div>
        </div>
    </div>
);

export default Home;
