import ourTeamImg from '../../../../assets/images/our-team.png';
import ourTeamMobileImg from '../../../../assets/images/our-team-mobile.png';
import styles from './OurTeam.module.scss';

const OurTeam = () => (
    <div id="ourTeam" className={styles.sectionContainer}>
        <div className={styles.titleContainer}>
            <h2 className={styles.title}>OUR TEAM</h2>
        </div>
        <div className={styles.imageContainer}>
            <picture>
                <source srcSet={ourTeamImg} type="image/jpeg" media="(min-width: 1000px)" />
                <source srcSet={ourTeamMobileImg} type="image/jpeg" />
                <img src={ourTeamImg} alt="" className={styles.image} loading="lazy" />
            </picture>
        </div>
    </div>
);

export default OurTeam;
