import { Dispatch, SetStateAction, useRef } from 'react';
import ReactDOM from 'react-dom';
import useTracking from '../../../hooks/useTracking';
import styles from './CookiesModal.module.scss';

interface IProps {
    setShowCookies: Dispatch<SetStateAction<boolean>>;
}

const CookiesModal = ({ setShowCookies }: IProps) => {
    const { initiateTracking } = useTracking();
    const containerRef = useRef(null);

    const onAcceptCookies = () => {
        localStorage.setItem('cookiesAcceptance', 'true');
        setShowCookies(false);
        initiateTracking();
    };

    const onDeclineCookies = () => {
        setShowCookies(false);
    };

    return ReactDOM.createPortal(
        <div className={styles.container}>
            <div className={styles.boxWrapper}>
                <div className={styles.box} ref={containerRef}>
                    <div className={styles.whiteLine} />
                    <h2 className={styles.title}>We care about your Privacy</h2>
                    <p className={styles.description}>
                        We use cookies to enhance your browsing experience, serve personalized ads or content, and
                        analyze our traffic. By clicking &quot;Accept All&quot;, you consent to our use of cookies.
                    </p>
                    <div className={styles.buttonsWrapper}>
                        <button type="button" onClick={onDeclineCookies} className={styles.declineButton}>
                            Decline
                        </button>
                        <button type="button" onClick={onAcceptCookies} className={styles.acceptButton}>
                            <div className={styles.acceptButtonBackground} />
                            <span className={styles.acceptButtonText}>Accept</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById('root')!
    );
};

export default CookiesModal;
