import styles from './AboutUs.module.scss';
import Slider from './slider/Slider';

const AboutUs = () => (
    <div id="aboutUs" className={styles.sectionContainer}>
        <div className={styles.sectionWrapper}>
            <div className={styles.textContainer}>
                <h2 className={styles.title}>ABOUT US</h2>
                <p className={styles.description}>
                    Royal Hawk Club, a revolutionary NFT project developed by KubeCoin. We created 7777 Hawks on the
                    Cardano Blockchain to combine technology, style and exclusive, real-world benefits for our
                    community.
                </p>
            </div>
            <Slider />
        </div>
    </div>
);

export default AboutUs;
