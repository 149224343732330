import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

function usePageViews() {
    const isConsent = localStorage.getItem('cookiesAcceptance');
    const isDevelopEnv = process.env.NODE_ENV === 'development';
    const trackEvents = isConsent && !isDevelopEnv;

    useEffect(() => {
        if (trackEvents) initiateTracking();
    }, []);

    const initiateTracking = () => {
        TagManager.initialize({ gtmId: 'GTM-TTDZZVF' });
        TagManager.initialize({ gtmId: 'G-G18JKNG5DM' });
    };

    return { initiateTracking };
}

export default usePageViews;
