import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import CookiesModal from './components/global/cookiesModal/CookiesModal';
import CommingSoon from './components/pages/commingSoon/CommingSoon';
import Home from './components/pages/home/Home';
import useTracking from './hooks/useTracking';

const App = () => {
    const cookiesLocalStorage = localStorage.getItem('cookiesAcceptance');
    const [showCookies, setShowCookies] = useState<boolean>(cookiesLocalStorage === null);

    useTracking();

    return (
        <>
            {showCookies && <CookiesModal setShowCookies={setShowCookies} />}
            <main>
                <Routes>
                    {/* <Route path="*" element={<CommingSoon />} /> */}
                    <Route path="*" element={<Home />} />
                </Routes>
            </main>
        </>
    );
};

export default App;
