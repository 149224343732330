interface IProps {
    desktopImg: string;
    desktopWebp?: string;
    mobileImg?: string;
    mobileWebp?: string;
    mediaWidth?: string | number;
    className?: any;
    lazyLoad?: boolean;
    alt?: string;
}

const Picture = ({
    desktopImg,
    desktopWebp,
    mobileImg,
    mobileWebp,
    mediaWidth = '480',
    className,
    lazyLoad = false,
    alt = '',
}: IProps) => (
    <picture>
        {desktopWebp && <source srcSet={desktopWebp} type="image/webp" media={`(min-width: ${mediaWidth}px)`} />}
        <source srcSet={desktopImg} type="image/jpeg" media={`(min-width: ${mediaWidth}px)`} />
        {mobileWebp && <source srcSet={mobileWebp} type="image/webp" />}
        <source srcSet={mobileImg} type="image/jpeg" />
        <img src={desktopImg} alt={alt} className={className} loading={lazyLoad ? 'lazy' : undefined} />
    </picture>
);

export default Picture;
