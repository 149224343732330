import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logoMobile from '../../../../assets/images/logo-mobile.svg';
import styles from './MenuMobile.module.scss';
import closeIcon from '../../../../assets/icons/close.svg';
import { navLinks, socialLinks } from '../../../../utils/links';

interface IProps {
    closeMenu: () => void;
}

const MenuMobile = ({ closeMenu }: IProps) => {
    const location = useLocation();
    const { pathname, hash } = location;
    const currentPathname = `${pathname}${hash}`;

    return (
        <div className={styles.menuContainer}>
            <div className={styles.menuHeader}>
                <div className={styles.logoContainer}>
                    <img src={logoMobile} className={styles.logoMobile} alt="" />
                </div>
                <button className={styles.closeButton} onClick={closeMenu}>
                    <img src={closeIcon} alt="" />
                </button>
            </div>

            <div className={styles.navContainer}>
                <nav className={styles.nav}>
                    <ul className={styles.linksContainer}>
                        {navLinks.map((navLink) => {
                            const isActive =
                                `/${navLink.path}` === currentPathname ||
                                (navLink.name === 'HOME' && currentPathname === '/');
                            const linkClassName = isActive ? `${styles.link} ${styles.linkActive}` : styles.link;

                            return (
                                <li key={navLink.name} className={linkClassName}>
                                    <HashLink to={navLink.path} onClick={closeMenu}>
                                        {navLink.name}
                                    </HashLink>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
                {/*                 <button className={styles.ctaButton} onClick={closeMenu}>
                    BUTTON CTA 1
                </button> */}
            </div>

            <div className={styles.socialContainer}>
                <ul>
                    {socialLinks.map((link) => (
                        <li key={link.text}>
                            <a href={link.href} target="_blank" rel="noreferrer">
                                <img src={link.src} className={styles.socialIcon} alt={`${link.text} icon`} />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MenuMobile;
